import { IncomingOptions } from 'use-http';

// FETCH CONFIGURATIONS
export const cdnUrl: string = 'https://strgophirdev.blob.core.windows.net/values';
export const apiOptions: IncomingOptions = {};
// let defaultUrl = 'https://localhost:5001';
let defaultUrl = 'https://dev-api.shareimpulse.com/';
export const apiUrl: string = defaultUrl;

// B2C Configurations

const b2cPolicies = {
    names: {
        signUpSignIn: 'B2C_1_Login',
        forgotPassword: 'B2C_1_ResetPassword',
        editProfile: 'B2C_1_EditProfile'
    },
    authorities: {
        signUpSignIn: {
            authority: 'https://siauth.b2clogin.com/siauth.onmicrosoft.com/B2C_1_Login'
        },
        forgotPassword: {
            authority: 'https://siauth.b2clogin.com/siauth.onmicrosoft.com/B2C_1_ResetPassword'
        },
        editProfile: {
            authority: 'https://siauth.b2clogin.com/siauth.onmicrosoft.com/B2C_1_EditProfile'
        }
    },
    authorityDomain: 'siauth.b2clogin.com'
};

export const apiConfig = {
    scopes: ['https://shareimpulse.net/ophir-api-dev/Core.All'],
    apiHost: 'https://localhost:5001'
};

export const msalConfig = {
    auth: {
        clientId: '212eb5f8-8f78-4713-99a5-28671b395701',
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain]
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    }
};

export const loginRequest = {
    scopes: ['openid', 'profile']
};
